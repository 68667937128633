import React, { useContext, useEffect, useState } from "react";
import { useBasket } from "../basketContext";
import { AuthContext } from "../AuthContext.js";
import { useNavigate } from "react-router-dom";
import formatCurrency from "../utility/formatCurrency";
import { TrashIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Button from "./Button";

const Basket = (props) => {
  const { handleClose } = props;
  const { basketItems, removeItem } = useBasket();
  const { session } = useContext(AuthContext);
  const productIds = basketItems.map((item) => item.id);
  const [subtotal, setSubtotal] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    calculateSubtotal();
  }, [basketItems]);

  const handleRemoveItem = (itemId) => {
    removeItem(itemId);
  };

  const calculateSubtotal = () => {
    const amount = basketItems.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
    setSubtotal(amount);
  };

  const handleSubmit = async () => {
    try {
      if (session?.user?.id) {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/create-checkout-session`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: session.user.id,
              productIds,
              basketItems,
            }),
          }
        );
        const sessionData = await response.json();
        window.location.href = sessionData.url; // Redirect to Stripe checkout
      } else {
        handleClose();
        navigate("/login", {
          state: {
            message: "Please log in to proceed to checkout",
            redirectUrl: "/checkout",
          },
        });
      }
    } catch (error) {
      console.error("Error during checkout:", error);
    }
  };

  return (
    <div className="m-4 text-slate-900 flex flex-col justify-between h-full">
      <header className="flex justify-between items-center">
        <h2 className="text-2xl font-medium">Basket</h2>
        <button
          type="button"
          className="rounded-full bg-white p-2 text-slate-900 hover:bg-slate-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleClose}
        >
          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </header>
      {basketItems.length > 0 ? (
        <>
          <div className="my-6 divide-y-2 h-full">
            {basketItems.map((item) => (
              <div
                key={item.id}
                className="flex justify-between items-center py-4"
              >
                <div>
                  <div className="flex flex-row items-center">
                    <img
                      src={item.image}
                      className="object-cover h-12 w-12 rounded-lg mr-4"
                    />
                    <div className="flex flex-col">
                      <div className="text-base font-medium">{item.title}</div>
                      <div>{formatCurrency(item.price)}</div>
                      <div>Quantity: {item.quantity}</div>
                    </div>
                  </div>
                </div>
                <button
                  className="p-4"
                  onClick={() => handleRemoveItem(item.id)}
                >
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            ))}
          </div>
          <footer className="py-8">
            <div className="my-4">
              <p className="text-xl font-medium">
                Subtotal: {formatCurrency(subtotal)}
              </p>
              <p className="text-sm text-slate-400">Delivery calculated at checkout.</p>
            </div>
            <div>
              <Button onClick={handleSubmit} className="text-xl font-medium">
                Proceed to Checkout
              </Button>
            </div>
          </footer>
        </>
      ) : (
        <div className="my-6 h-full">Your basket is empty</div>
      )}
    </div>
  );
};

export default Basket;
