import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../AuthContext";
import Cards from "../../components/Cards";
import { fetchData } from "../../utility/fetchData";
import { postData, postToApi } from "../../utility/postData";
import ImagesWithDetails from "../../components/ImagesWithDetails";
import TextArea from "../../components/TextArea";
import Button from "../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import Divider from "../../components/Divider/Divider";
import axios from "axios";
import PageHeader from "../../components/PageHeader/PageHeader";
import InputKeywords from "../../components/InputKeywords/InputKeywords";
import { XMarkIcon } from "@heroicons/react/20/solid";
import CheckCircleIcon from "@heroicons/react/20/solid/CheckCircleIcon";
import { motion } from "framer-motion";
import Spinner from "../../components/Spinner/Spinner";
import ProgressBar from "../../components/ProgressBar/ProgressBar";

const steps = [
  "Select Orientation",
  "Select Style",
  "Prompt Style",
  "Generate Prompt",
  "Include Items",
];

const GeneratePrint = () => {
  const { session, createCredits } = useContext(AuthContext);
  const navigate = useNavigate();
  const { slug } = useParams();
  const [userSelection, setUserSelection] = useState({
    orientation: null,
    style: null,
    prompt: null,
    promptType: null,
  });
  const [orientations, setOrientations] = useState([]);
  const [styles, setStyles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [imageDescription, setImageDescription] = useState(null);
  const [promptType, setPromptType] = useState({
    id: 1,
    friendly_name: "Text Description",
    value: "describe",
  });
  const [promptData, setPromptData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [promptKeywords, setPromptKeywords] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [isScrolledDown, setIsScrolledDown] = useState(false);
  const [userCreateCredits, setUserCreateCredits] = useState(0);
  const [jobData, setJobData] = useState(null);
  const [generationParams, setGenerationParams] = useState({
    promptValue: null,
    colorScheme: null,
  });

  const fetchDataAsync = useCallback(async () => {
    try {
      const [orientationData, styleData, promptData] = await Promise.all([
        fetchData("prompt_sizes", "*"),
        fetchData("art_styles", "*"),
        fetchData("prompt_type", "*"),
      ]);
      setOrientations(orientationData);
      setStyles(styleData);
      setPromptData(promptData);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (slug && styles.length > 0) {
      checkExistingJob();
    }
  }, [slug, styles]);

  const checkExistingJob = async () => {
    try {
      const jobResponse = await fetchData("generation_jobs", "*", { id: slug });

      if (jobResponse && jobResponse[0].user_id === session.user.id) {
        const job = jobResponse[0];
        setJobData(job);
        setUserSelection({
          orientation: orientations.find((item) => item.id === job.prompt_size),
          style: styles.find((item) => item.id === job.style),
          prompt: job.original_prompt,
          promptType: promptData.find((item) => item.id === job.prompt_type),
          keywords:
            job.keywords && job.keywords.length > 0 ? job.keywords : null,
        });
        setImageDescription(job.original_prompt);
      } else {
        console.error("Job not found or access denied.");
      }
    } catch (error) {
      console.error("There was an error fetching the existing job", error);
    }
  };

  useEffect(() => setUserCreateCredits(createCredits), [createCredits]);

  useEffect(() => {
    fetchDataAsync();
  }, [fetchDataAsync]);

  useEffect(() => {
    let lastScrollTop = 20;

    const onScroll = (e) => {
      let st = document.documentElement.scrollTop;
      if (st > lastScrollTop) {
        setIsScrolledDown(true);
      } else if (st === 0) {
        setIsScrolledDown(false);
      }
      lastScrollTop = st <= 0 ? 0 : st;
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handlePromptChange = useCallback((e) => {
    setUserSelection((currentSelection) => ({
      ...currentSelection,
      prompt: e.target.value,
    }));
  }, []);

  const handleStyleSelection = useCallback((data) => {
    setUserSelection((currentSelection) => ({
      ...currentSelection,
      style: data,
    }));
  }, []);

  const handleOrientationSelection = useCallback((data) => {
    setUserSelection((currentSelection) => ({
      ...currentSelection,
      orientation: data,
    }));
  }, []);

  const handlePromptTypeSelection = (item) => {
    setPromptType(item);
    setUserSelection((currentSelection) => ({
      ...currentSelection,
      promptType: item,
    }));
  };

  const handlePromptKeywordsChange = useCallback((data) => {
    setUserSelection((currentSelection) => ({
      ...currentSelection,
      keywords: data,
    }));
  }, []);

  const getColorScheme = async () => {
    const formData = new FormData();
    formData.append("promptImage", selectedFile);
    const baseUrl = process.env.REACT_APP_API_URL;
    try {
      const colorSchemeResponse = await axios.post(
        `${baseUrl}/generate-colour-scheme`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return colorSchemeResponse.data;
    } catch (error) {
      console.error("Error fetching color scheme:", error);
    }
  };

  const getImageDescription = async () => {
    const formData = new FormData();
    formData.append("promptImage", selectedFile);
    formData.append("promptType", promptType.value);
    formData.append("keywords", userSelection.keywords);

    const baseUrl = process.env.REACT_APP_API_URL;

    try {
      const descriptionRequest = await axios.post(
        `${baseUrl}/get-image-description`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const imageDescriptionResponse = await descriptionRequest;
      const prompt = imageDescriptionResponse.data.data;
      let description = prompt;
      return description;
    } catch (error) {
      console.error("Error describing image:", error);
    }
  };

  useEffect(() => {
    const { promptValue, colorScheme } = generationParams;
    if (promptValue && colorScheme) {
      postGenerationJobWrapper(promptValue, colorScheme);
    }
  }, [generationParams]);

  const handleGenerateBtn = async () => {
    goToNextStep();
    setSubmitting(true);

    let promptValue;

    if (
      (userSelection.promptType !== null &&
        userSelection.promptType.value === "printPhoto") ||
      (userSelection.promptType.value === "interiorPhoto" && selectedFile)
    ) {
      try {
        const imageDescriptionValue = await getImageDescription();
        if (!imageDescriptionValue) {
          console.error("Failed to obtain image description.");
          setSubmitting(false);
          return; // Exit the function if no description is obtained
        }
        promptValue = imageDescriptionValue; // Use the image description as the prompt
        setImageDescription(imageDescriptionValue); // Update state for other uses

        const imageColorScheme = await getColorScheme();
        if (!imageColorScheme) {
          console.error("Failed to obtain image color scheme.");
          setSubmitting(false);
          setError("Error processing image");
          return;
        }

        // Set combined state to trigger useEffect
        setGenerationParams({
          promptValue,
          colorScheme: imageColorScheme,
        });
      } catch (error) {
        console.error("Error processing image", error);
        setSubmitting(false);
        setError("Error processing image");
        return;
      }
    } else if (userSelection.promptType.value === "describe") {
      promptValue = userSelection.prompt; // Use the user-provided description

      if (!promptValue) {
        console.error("Please provide a description for the print.");
        setSubmitting(false);
        return; // Exit if no description provided
      }
      // Set combined state to trigger useEffect
      setGenerationParams({
        promptValue,
        colorScheme: {}, // Provide a default value to prevent null
      });
    } else {
      console.error("Invalid prompt type.");
      setSubmitting(false);
      return; // Exit if prompt type is not handled
    }
  };

  const postGenerationJobWrapper = async (promptValue, colorScheme) => {
    const result = await postGenerationJob("generation_jobs", {
      prompt_size: userSelection?.orientation?.id
        ? userSelection.orientation.id
        : 2,
      original_prompt: promptValue,
      user_id: session.user.id,
      style: userSelection?.style?.id ? userSelection.style.id : null,
      keywords: userSelection.keywords,
      prompt_type: promptType.id,
      seed_id: slug ? slug : null,
      color_category: colorScheme?.colorCategory,
      color_psychology_impact_level: colorScheme?.colorPsychology?.impactLevel,
      color_psychology_primary_trait:
        colorScheme?.colorPsychology?.primaryTrait,
      recommended_room: colorScheme?.recommendedRoom,
    });

    if (
      result.error === null &&
      result.data &&
      result.data.imageData &&
      result.data.imageData.url
    ) {
      const slug = result.data.jobDetails.id;
      navigate(`/generated-print/${slug}`);
    } else {
      console.log("There was an error" + result.error);
    }
  };

  const postGenerationJob = async (table, data) => {
    let responseData = {
      data: null,
      error: null,
    };

    try {
      const response = await postData(table, data);

      if (response.error) {
        console.error("Error posting data: ", response.error);
        responseData.error = response.error;
        return responseData;
      }

      try {
        const generateImage = await postToApi(
          `${process.env.REACT_APP_API_URL}/generate-image`,
          {
            prompt: data.original_prompt,
            size: userSelection.orientation.value,
            style: userSelection?.style?.value,
            user: session.user.id,
            job_id: response.data[0].id,
            keywords: userSelection.keywords,
            forceOriginalPrompt: process.env.OPENAI_FORCE_ORIGINAL_PROMPT,
            colorScheme: generationParams.colorScheme,
          }
        );

        if (generateImage.error) {
          console.error("Failed to post data:", generateImage.error);
          responseData.error = generateImage.error;
        } else {
          responseData.data = {
            jobDetails: response.data[0],
            imageData: generateImage.data.response,
          };
        }
      } catch (error) {
        console.error("Error in postGenerationJob: ", error);
        responseData.error = error;
      } finally {
        setSubmitting(false);
      }
    } catch (error) {
      console.error("Error in postGenerationJob: ", error);
      responseData.error = error;
    } finally {
      setSubmitting(false);
    }

    return responseData;
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const goToNextStep = () => {
    setCurrentStep((prev) => (prev < steps.length - 1 ? prev + 1 : prev));
  };

  const goToPreviousStep = () => {
    setCurrentStep((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleMobileClose = () => {
    navigate("/");
  };

  return (
    <div className="container mx-auto p-8 h-full">
      {userCreateCredits < 5 ? (
        <>
          <div
            className={`flex flex-col ${
              !submitting ? "justify-center " : ""
            }h-full ${isScrolledDown ? "pt-48" : ""} md:hidden`}
          >
            {loading ? (
              <div>Loading...</div>
            ) : error ? (
              <div>There was an error. Please try again.</div>
            ) : (
              <>
                {!submitting ? (
                  <>
                    <header
                      className={`${
                        isScrolledDown
                          ? "bg-[#f0f0eb] shadow-md fixed top-0 left-0 w-full z-10 px-8 pt-8 pb-4"
                          : ""
                      }`}
                    >
                      <div className="flex justify-between items-start">
                        <motion.div
                          animate={{
                            fontSize: isScrolledDown ? "1.25rem" : "1.75rem",
                          }}
                          transition={{ duration: 0.15 }}
                          initial={false}
                          className="text-4xl font-medium text-slate-950"
                        >
                          Create Your Own Art Print
                        </motion.div>

                        <button
                          type="button"
                          className="rounded-full bg-white p-2 text-slate-900 hover:bg-slate-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={handleMobileClose}
                        >
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                      <div className={isScrolledDown ? "my-2" : "my-4"}>
                        <h2
                          className={`${
                            isScrolledDown ? "text-base" : "text-xl"
                          } font-medium mb-2`}
                        >
                          Step {currentStep + 1}:{" "}
                          {currentStep === 3
                            ? promptType.value === "describe"
                              ? "Describe Your Print"
                              : promptType.value === "printPhoto"
                              ? "Upload a Photo of Your Print"
                              : "Upload a Photo of Your Interior"
                            : steps[currentStep]}
                        </h2>
                        <ProgressBar current={currentStep} total={steps} />
                      </div>
                      {!isScrolledDown && (
                        <div className="mb-8">
                          {currentStep >= 1 && (
                            <motion.div animate={{ opacity: 1 }}>
                              <CheckCircleIcon className="inline h-6 w-6 mr-2" />
                              Orientation:{" "}
                              <strong>
                                {userSelection.orientation !== null
                                  ? userSelection.orientation.friendly_name
                                  : "Random"}
                              </strong>
                            </motion.div>
                          )}
                          {currentStep >= 2 && (
                            <motion.div animate={{ opacity: 1 }}>
                              <CheckCircleIcon className="inline h-6 w-6 mr-2" />
                              Art Style:{" "}
                              <strong>
                                {userSelection.style
                                  ? userSelection.style.friendly_name
                                  : "Any"}
                              </strong>
                            </motion.div>
                          )}
                          {currentStep >= 3 && promptType.value !== null && (
                            <motion.div animate={{ opacity: 1 }}>
                              <CheckCircleIcon className="inline h-6 w-6 mr-2" />
                              Generation Method:{" "}
                              <strong>
                                {promptType.value === "describe"
                                  ? "Text Description"
                                  : "Photo Upload"}
                              </strong>
                            </motion.div>
                          )}
                          {currentStep >= 4 && (
                            <motion.div animate={{ opacity: 1 }}>
                              <CheckCircleIcon className="inline h-6 w-6 mr-2" />
                              Prompt:{" "}
                              <strong>
                                {selectedFile !== null
                                  ? "Photo"
                                  : "Text Description"}
                              </strong>
                            </motion.div>
                          )}
                        </div>
                      )}
                    </header>
                    {currentStep === 0 && (
                      <Cards
                        data={orientations}
                        defaultSelection={
                          userSelection?.orientation?.id
                            ? userSelection.orientation.id
                            : null
                        }
                        onChange={handleOrientationSelection}
                        showIcon={false}
                      />
                    )}
                    {currentStep === 1 && (
                      <ImagesWithDetails
                        defaultSelection={
                          userSelection?.style ? userSelection.style : null
                        }
                        data={styles}
                        onChange={handleStyleSelection}
                      />
                    )}
                    {currentStep === 2 && (
                      <Cards
                        onChange={handlePromptTypeSelection}
                        defaultSelection={
                          userSelection?.promptType && userSelection.promptType
                            ? userSelection.promptType.id
                            : promptType.id
                        }
                        data={promptData}
                        showIcon={false}
                      />
                    )}
                    {currentStep === 3 && (
                      <>
                        {promptType.value === "describe" ? (
                          <div name="Describe It">
                            <div className="my-8">
                              <h3 className="text-2xl font-medium mb-4">
                                Describe your print
                              </h3>
                              <p className="text-lg font-medium text-slate-800">
                                Describe the art you want to generate using
                                words.
                              </p>
                              <TextArea
                                onChange={handlePromptChange}
                                value={userSelection.prompt}
                                name="prompt"
                                placeholder="Minimalist watercolor landscape with soft, neutral tones, featuring a serene lake with a single boat and a faint silhouette of mountains in the background, ensuring any negative space is pure white."
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="py-8 md:p-12 text-center">
                            {promptType.value === "printPhoto" ? (
                              <div className="flex flex-col items-center">
                                <div>
                                  <img
                                    className="mb-6 w-28 md:w-32"
                                    src="prompt-type-existing-print.png"
                                  />
                                </div>
                                <h3 className="text-3xl my-2 font-medium">
                                  Upload or Take a Photo of an Existing Print
                                </h3>
                                <p className="text-lg text-slate-800 mb-8">
                                  Create a print that compliments your existing
                                  artwork.
                                </p>
                              </div>
                            ) : (
                              <div className="flex flex-col items-center">
                                <div>
                                  <img
                                    className="mb-6 w-40 md:w-48"
                                    src="prompt-type-interior.png"
                                  />
                                </div>
                                <h3 className="text-3xl font-medium mb-2">
                                  Upload or Take a Photo of a Room
                                </h3>
                                <p className="text-lg text-slate-800 mb-8">
                                  Create a print that compliments your interior.
                                </p>
                              </div>
                            )}

                            <label htmlFor="imageUpload" className="sr-only">
                              Upload or Take a Photo:
                            </label>
                            <input
                              type="file"
                              id="imageUpload"
                              accept="capture,image/*"
                              onChange={handleFileChange}
                              className="p-2 bg-white border-2 border-dashed border-slate-950 rounded-md"
                            />
                            {uploadedImageUrl && (
                              <img
                                src={uploadedImageUrl}
                                alt="Uploaded"
                                style={{ maxWidth: "100%" }}
                              />
                            )}
                          </div>
                        )}
                      </>
                    )}
                    {currentStep === 4 && (
                      <div className="my-8 flex flex-col items-start">
                        <label className="block mb-2">
                          Optionally add keywords of things you'd like to
                          include
                        </label>
                        <InputKeywords
                          keywords={userSelection.keywords}
                          onChange={handlePromptKeywordsChange}
                          className="w-full"
                        />
                      </div>
                    )}
                    <div className="flex justify-between mt-6">
                      {currentStep === 0 && (
                        <Button
                          onClick={handleMobileClose}
                          className="bg-white enabled:hover:!bg-white !text-slate-950 border border-solid border-slate-950 w-1/2 mr-4"
                        >
                          Cancel
                        </Button>
                      )}
                      {currentStep > 0 && (
                        <Button
                          onClick={goToPreviousStep}
                          className="bg-white enabled:hover:!bg-white !text-slate-950 border border-solid border-slate-950 w-1/2 mr-4"
                        >
                          Previous
                        </Button>
                      )}
                      {currentStep < steps.length - 1 && (
                        <Button onClick={goToNextStep} className="w-1/2">
                          Next
                        </Button>
                      )}

                      {currentStep === steps.length - 1 && (
                        <Button
                          onClick={handleGenerateBtn}
                          disabled={submitting}
                          loading={submitting}
                          className="w-1/2"
                        >
                          Generate
                        </Button>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="flex flex-col text-center">
                    <div className="self-center mb-4">
                      <Spinner size={48} speed="0.5s" />
                    </div>
                    <h3 className="text-xl">
                      {!imageDescription
                        ? `Analysing Your ${
                            promptType.value === "describe"
                              ? "Description"
                              : "Photo"
                          }`
                        : "Creating Image"}
                    </h3>
                    <p className="text-slate-600 mt-1">
                      {!imageDescription
                        ? "Generating Description"
                        : "Please wait..."}
                    </p>
                    {imageDescription ? (
                      <motion.div
                        initial={{ opacity: 0, y: 100 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ ease: "easeOut", duration: 0.25 }}
                        className="my-8"
                      >
                        <Divider />
                        <h4 className="text-lg mb-4 mt-8">
                          Description of Your Art Print
                        </h4>
                        <p className="text-sm">{imageDescription}</p>
                      </motion.div>
                    ) : null}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="hidden md:block">
            {loading ? (
              <div>loading</div>
            ) : !error ? (
              <>
                <PageHeader title="Create Your Own Art Print" />

                <div className="py-8">
                  <h2 className="text-2xl font-medium">Orientation</h2>
                  <Cards
                    data={orientations}
                    defaultSelection={
                      userSelection?.orientation?.id
                        ? userSelection?.orientation?.id
                        : null
                    }
                    onChange={handleOrientationSelection}
                    showIcon={false}
                  />
                </div>
                <Divider />

                <div className="py-8">
                  <h2 className="mt-8 text-2xl font-semibold leading-6 text-gray-900 mb-6">
                    Select a Style
                  </h2>
                  <ImagesWithDetails
                    defaultSelection={
                      userSelection?.style ? userSelection.style : null
                    }
                    data={styles}
                    onChange={handleStyleSelection}
                  />
                </div>
                <Divider />

                <div className="pt-8">
                  <h2 className="text-2xl font-medium">
                    How would you like to generate your print?
                  </h2>
                  <Cards
                    onChange={handlePromptTypeSelection}
                    defaultSelection={
                      userSelection?.promptType && userSelection.promptType
                        ? userSelection.promptType.id
                        : promptType.id
                    }
                    data={promptData}
                    showIcon={false}
                  />

                  {promptType.value === "describe" ? (
                    <div name="Describe It">
                      <div className="my-8">
                        <h3 className="text-2xl font-medium mb-4">
                          Describe your print
                        </h3>
                        <p className="text-lg font-medium text-slate-800">
                          Describe the art you want to generate using words.
                        </p>
                        <TextArea
                          onChange={handlePromptChange}
                          value={userSelection.prompt}
                          name="prompt"
                          placeholder="Minimalist watercolor landscape with soft, neutral tones, featuring a serene lake with a single boat and a faint silhouette of mountains in the background, ensuring any negative space is pure white."
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="py-8 md:p-12 text-center">
                      {promptType.value === "printPhoto" ? (
                        <div className="flex flex-col items-center">
                          <div>
                            <img
                              className="mb-6 w-28 md:w-32"
                              src="prompt-type-existing-print.png"
                            />
                          </div>
                          <h3 className="text-3xl my-2 font-medium">
                            Upload or Take a Photo of an Existing Print
                          </h3>
                          <p className="text-lg text-slate-800 mb-8">
                            Create a print that compliments your existing
                            artwork.
                          </p>
                        </div>
                      ) : (
                        <div className="flex flex-col items-center">
                          <div>
                            <img
                              className="mb-6 w-40 md:w-48"
                              src="prompt-type-interior.png"
                            />
                          </div>
                          <h3 className="text-3xl font-medium mb-2">
                            Upload or Take a Photo of a Room
                          </h3>
                          <p className="text-lg text-slate-800 mb-8">
                            Create a print that compliments your interior.
                          </p>
                        </div>
                      )}

                      <label htmlFor="imageUpload" className="sr-only">
                        Upload or Take a Photo:
                      </label>
                      <input
                        type="file"
                        id="imageUpload"
                        accept="image/*"
                        capture="environment"
                        onChange={handleFileChange}
                        className="p-2 bg-white border-2 border-dashed border-slate-950 rounded-md"
                      />
                      {uploadedImageUrl && (
                        <img
                          src={uploadedImageUrl}
                          alt="Uploaded"
                          style={{ maxWidth: "100%" }}
                        />
                      )}
                      <div className="mt-6 flex flex-col items-start">
                        <label className="block mb-2">
                          Add keywords of items you want included in your print
                        </label>
                        <InputKeywords
                          keywords={userSelection.keywords}
                          onChange={handlePromptKeywordsChange}
                          className="w-full"
                        />
                      </div>
                      {imageDescription ? (
                        <>
                          <Divider />
                          <div className="mb-8">
                            <p>{imageDescription}</p>
                          </div>
                        </>
                      ) : null}
                    </div>
                  )}
                </div>
                <Button
                  onClick={handleGenerateBtn}
                  disabled={submitting}
                  loading={submitting}
                  className="w-full"
                >
                  {submitting
                    ? "Generating Your Print - This can take ~1min"
                    : "Generate"}
                </Button>
              </>
            ) : (
              <div>There was an error. Please try again.</div>
            )}
          </div>
        </>
      ) : (
        <div className="flex flex-col justify-center text-center p-8">
          <h1 className="text-3xl text-slate-950 mb-4">
            No Create Credits Remaining
          </h1>
          <p className="mb-8">
            Sorry you've used all your create credits. Each time you create a
            piece of art you use one Create Credit. To create additional art
            prints, please make a purchase or wait 30 days when you'll be
            rewarded 5 credits.
          </p>
          <Button url="/account">View Your Art Gallery</Button>
        </div>
      )}
    </div>
  );
};

export default GeneratePrint;
