import React from "react";
import { useParams, useLocation } from "react-router-dom";
import ArtGallery from "../../components/ArtGallery/ArtGallery";
import PageHeader from "../../components/PageHeader/PageHeader";

const Collections = () => {
  const { slug } = useParams();
  const location = useLocation();

  // Define the mapping of known collection types to filter fields
  const filterMapping = {
    "prints-by-colors": "color_category",
    "prints-by-room": "recommended_room",
    "prints-by-feeling": "color_psychology_primary_trait",
    "prints-by-impact": "color_psychology_impact_level"
  };

  // Extract the relevant part of the URL path to determine the filter
  const pathParts = location.pathname.split('/').filter(Boolean);
  let filters = {};
  let title = "Collections";

  if (pathParts.length >= 2) {
    const collectionTypeOrKeyword = pathParts[1]; // E.g., "prints-by-room" or "Mountain-prints"
    
    if (collectionTypeOrKeyword.includes('-prints')) {
      // Handling Keyword-based filtering: {Keyword}-prints
      const keyword = collectionTypeOrKeyword.replace('-prints', '');
      filters.keywords = [capitalizeFirstLetter(keyword)];
      title = `${capitalizeFirstLetter(keyword)} Prints`;
    } else {
      // Handling other predefined mappings
      const filterKey = filterMapping[collectionTypeOrKeyword];
      if (filterKey && pathParts.length >= 3) {
        const filterValue = pathParts.slice(2).join("-");
        filters[filterKey] = formatValue(filterKey, filterValue);

        // Set the title based on the collection type and filter value
        title = generateTitle(collectionTypeOrKeyword, filters[filterKey]);
      }
    }
  }

  // Function to format values based on the filter key
  function formatValue(key, value) {
    if (key === "recommended_room") {
      return value.split("-").map(capitalizeFirstLetter).join(" ");
    }
    return capitalizeFirstLetter(value);
  }

  // Helper function to capitalize the first letter
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  // Function to generate the title based on the collection type and value
  function generateTitle(collectionType, filterValue) {
    switch (collectionType) {
      case "prints-by-colors":
        return `Prints in ${filterValue}`;
      case "prints-by-room":
        return `Prints for your ${filterValue}`;
      case "prints-by-feeling":
        return `Prints that evoke ${filterValue}`;
      case "prints-by-impact":
        return `Prints with ${filterValue} impact`;
      default:
        return "Collections";
    }
  }

  return (
    <div className="container mx-auto p-8 max-w-2xl md:max-w-7xl">
      <PageHeader title={title} className="md:text-7xl"/>
      <div>
        <h2 className="w-full text-center text-3xl mb-6">Discover wall art for different rooms in your house</h2>
        <div className="flex flex-row">
          <div className="bg-white rounded-sm">
            <a href="/collections/prints-for-living-room" title="Prints for your living room">
            <h3>Living Room</h3>            
            </a>
          </div>
          <div className="bg-white rounded-sm">
            <a href="/collections/prints-for-kitchen" title="Prints for your kitchen">
            <h3>Kitchen</h3>            
            </a>
          </div>
          <div className="bg-white rounded-sm">
            <a href="/collections/prints-for-kids-room" title="Prints for your kids room">
            <h3>Kids Room</h3>            
            </a>
          </div>
        </div>
      </div>
      <ArtGallery className="md:columns-5" resultsPerPage={5} filters={filters} />
    </div>
  );
};

export default Collections;
