// OrdersPage.js
import React, { useState, useEffect } from 'react';
import { fetchData } from "../../utility/fetchData.js";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchOrders = async () => {
      const fetchedOrders = await fetchData("orders", "id, created_at, order_items (description, digital_download, price)");
      if (fetchedOrders.error) {
        setError(fetchedOrders.error.message);
        return;
      }

      const orderDetails = fetchedOrders.map(order => {
        const totalPrice = order.order_items.reduce((acc, item) => acc + item.price, 0);
        const hasDigitalDownload = order.order_items.some(item => item.digital_download);

        return { ...order, totalPrice, hasDigitalDownload };
      });

      setOrders(orderDetails);
    };
    fetchOrders();    
  }, []);

  return (
    <div className="container mx-auto p-4">
      {error ? (
        <div className="text-red-500">Error: {error}</div>
      ) : (
        <table className="table-auto w-full text-left">
          <thead>
            <tr className="bg-white">
              <th className="px-4 py-2">Order ID</th>
              <th className="px-4 py-2">Date and Time</th>              
              <th className="px-4 py-2">Items Ordered</th>
              <th className="px-4 py-2">Total Price</th>
              <th className="px-4 py-2">Inc. Digital Downloads</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order.id}>
                <td className="px-4 py-2">{order.id}</td>
                <td className=" px-4 py-2">{new Date(order.created_at).toLocaleString()}</td>                
                <td className=" px-4 py-2">{order.order_items.length}</td>
                <td className=" px-4 py-2">{(order.totalPrice / 100).toFixed(2)}</td>
                <td className=" px-4 py-2">{order.hasDigitalDownload ? 'Yes' : 'No'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Orders;
