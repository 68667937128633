import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { Tab } from "@headlessui/react";
import Cards from "./components/Cards";
import { useParams } from "react-router-dom";
import { fetchData } from "./utility/fetchData";
import { useBasket } from "./basketContext";
import { AuthContext } from "./AuthContext";
import formatCurrency from "./utility/formatCurrency";
import Button from "./components/Button";
import { AmendGeneratedImage } from "./components/AmendGeneratedImage.js";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ARImageViewer from "./components/ARImageViewer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const GeneratedPrint = () => {
  const { session } = useContext(AuthContext);
  const { slug } = useParams();
  const { addToBasket } = useBasket();
  const navigate = useNavigate();
  const [printData, setPrintData] = useState(null);
  const [printFormats, setPrintFormats] = useState([]);
  const [printSizes, setPrintSizes] = useState([]);
  const [printExtras, setPrintExtras] = useState([]);
  const [error, setError] = useState(false);
  const [price, setPrice] = useState(0);
  const [variantDetails, setVariantDetails] = useState(null);
  const [lastNonDigitalSize, setLastNonDigitalSize] = useState(null);
  const [addingToBasket, setAddingToBasket] = useState(false);
  const [ownedByUser, setOwnedByUser] = useState(false);
  const [showAmendOverlay, setShowAmendOverlay] = useState(false);

  const [userSelection, setUserSelection] = useState({
    format: 2,
    formatTitle: "Print Only",
    size: 4,
    sizeTitle: "A4",
    extras: null,
  });

  const fetchVariantDetails = useCallback(async () => {
    if (!userSelection.format) {
      return;
    }

    try {
      let whereClause = {
        product_id: "990f90ef-ec50-4bf2-8971-85ee065801f3", // Assuming a constant product ID, replace with actual logic if needed
        format_option_id: userSelection.format,
      };

      // Include size and extras in the where clause only if the format is not 'digital'
      if (userSelection.format !== "digital") {
        whereClause = {
          ...whereClause,
          ...(userSelection.size && { size_option_id: userSelection.size }),
          ...(userSelection.extras && {
            extra_option_id: userSelection.extras,
          }),
        };
      }

      const variantData = await fetchData(
        "product_variants",
        "*, products (id, friendly_name)",
        whereClause
      );

      if (variantData.length > 0) {
        setVariantDetails(variantData[0]);
      } else {
        console.error("No variant found for the selected options");
      }
    } catch (error) {
      console.error("Error fetching variant:", error);
    }
  }, [userSelection]);

  useEffect(() => {
    fetchVariantDetails();
  }, [userSelection, fetchVariantDetails]);

  useEffect(() => {
    if (printData && session && session?.user?.id === printData.user_id) {
      setOwnedByUser(true);
    }
  }, [printData]);

  const fetchPrintData = useCallback(async () => {
    try {
      const data = await fetchData(
        "generation_jobs",
        "id, title, original_prompt, revised_prompt, image_paths, user_id, keywords",
        { id: slug }
      );
      if (data.length > 0) {
        setPrintData(data[0]);
      }
    } catch (error) {
      console.error("Error fetching generation jobs: ", error);
      setError(true);
    }
  }, [slug]);

  const fetchPrintOptions = useCallback(async () => {
    try {
      const optionsData = await fetchData("product_options", "*");

      // Filter options by their type
      const printFormatsData = optionsData.filter(
        (option) => option.type === "Format"
      );
      const printSizesData = optionsData.filter(
        (option) => option.type === "Size"
      );
      const printExtrasData = optionsData.filter(
        (option) => option.type === "Extras"
      );

      setPrintFormats(printFormatsData);
      setPrintSizes(printSizesData);
      setPrintExtras(printExtrasData);

      const formatInfo = printFormatsData.find(
        (option) => option.value === "Print Only"
      );
      const sizeInfo = printSizesData.find((option) => option.value === "A4");

      if (printData && variantDetails && formatInfo && sizeInfo) {
        setUserSelection({
          id: optionsData.id,
          generationJobId: printData.id,
          productId: variantDetails.products.id,
          format: formatInfo.id,
          formatTitle: formatInfo.value,
          size: sizeInfo.id,
          sizeTitle: sizeInfo.value,
          extras: null,
          extrasTitle: null,
        });
      }
    } catch (error) {
      console.error("Error fetching print options: ", error);
      setError(true);
    }
  }, []);

  useEffect(() => {
    fetchVariantDetails();
  }, [userSelection, fetchVariantDetails]);

  const handleFormatChange = useCallback(
    (item) => {
      setUserSelection((currentSelection) => {
        let newSelection = {
          ...currentSelection,
          format: item.id,
          formatTitle: item.value,
        };

        if (item.value === "Digital") {
          // Save the current size before switching to Digital
          setLastNonDigitalSize(currentSelection.size);

          // Reset size and extras for Digital
          newSelection.size = null;
          newSelection.extras = null;
          newSelection.sizeTitle = null;
        } else {
          // If switching back from Digital, restore the last non-digital size
          // Only update size if it's currently null (meaning we're coming back from Digital)
          if (currentSelection.size === null && lastNonDigitalSize !== null) {
            newSelection.size = lastNonDigitalSize;
            // You may need to set sizePrice based on the size, which might require additional logic
          }
        }

        return newSelection;
      });
    },
    [lastNonDigitalSize]
  );

  const handleSizeChange = useCallback((item) => {
    setUserSelection((currentSelection) => {
      const newSelection = {
        ...currentSelection,
        size: item.id,
        sizeTitle: item.value,
        sizePrice: item.base_price,
      };

      return newSelection;
    });
  }, []);

  const handleExtrasChange = useCallback((item) => {
    setUserSelection((currentSelection) => {
      const newSelection = {
        ...currentSelection,
        extras: item.id,
        extrasTitle: item.value,
        extrasPrice: item.base_price,
      };

      return newSelection;
    });
  }, []);

  useEffect(() => {
    if (variantDetails) {
      setPrice(variantDetails.price);
    }
  }, [variantDetails]);

  const handleAddToBasket = async () => {
    setAddingToBasket(true);
    if (!variantDetails) {
      console.error("Variant details not found");
      return;
    }

    // Prepare the new item
    const newItem = {
      id: `${printData.id}/${variantDetails.id}`,
      variantId: variantDetails.id,
      generationJobId: printData.id,
      productId: variantDetails.products.id,
      sku: variantDetails.sku,
      price: variantDetails.price,
      title: `${printData.title} - ${variantDetails.products.friendly_name} - ${
        userSelection.formatTitle
      } ${userSelection.sizeTitle ? `- ${userSelection.sizeTitle}` : ""} ${
        userSelection.extrasTitle ? `- ${userSelection.extrasTitle}` : ""
      }`,
      image: printData.image_paths[0],
      quantity: 1,
    };

    try {
      await addToBasket(newItem);
    } catch (error) {
      console.error("There was an error adding the item to the basket.", error);
    } finally {
      setTimeout(() => setAddingToBasket(false), 1000);
    }
  };

  const handleNavigateToNewImage = (newSlug) => {
    toggleAmendOverlay();
    navigate(`/generated-print/${newSlug}`);
  };

  const toggleAmendOverlay = () => setShowAmendOverlay(!showAmendOverlay);

  const handleBackdropClick = (e) => {
    e.stopPropagation();
    setShowAmendOverlay(false);
  };

  useEffect(() => {
    const handleEscKey = (e) => {
      if (e.key === "Escape") {
        setShowAmendOverlay(false);
      }
    };

    if (showAmendOverlay) {
      window.addEventListener('keydown', handleEscKey);
      return () => window.removeEventListener('keydown', handleEscKey);
    }
  }, [showAmendOverlay]);

  useEffect(() => {
    fetchPrintData();
  }, [slug, fetchPrintData]);

  useEffect(() => {
    fetchPrintOptions();
  }, [fetchPrintOptions]);

  return (
    <div className="h-screen">
      {ownedByUser && (
        <div className="fixed bottom-6 left-6 z-10">
          <Button onClick={toggleAmendOverlay}>Amend</Button>
        </div>
      )}
      <div className="mx-auto max-w-2xl px-4 lg:py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        {printData ? (
          <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-14">
            {/* Image gallery */}
            <Tab.Group as="div" className="flex flex-col-reverse">
              {/* Image selector */}
              <div className="mx-auto mt-6 w-full max-w-2xl sm:block lg:max-w-none">
                <Tab.List className="grid grid-cols-4 gap-6">
                  {printData.image_paths.map((image, index) => (
                    <Tab
                      key={index}
                      className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                    >
                      {({ selected }) => (
                        <>
                          <span className="sr-only"></span>
                          <span className="absolute inset-0 overflow-hidden rounded-md">
                            <img
                              src={image}
                              alt=""
                              className="h-full w-full object-cover object-center"
                            />
                          </span>
                          <span
                            className={classNames(
                              selected ? "ring-indigo-500" : "ring-transparent",
                              "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </Tab>
                  ))}
                </Tab.List>
              </div>

              <Tab.Panels className=" w-full">
                {printData.image_paths.map((image, index) => (
                  <Tab.Panel key={index}>
                    <img
                      src={image}
                      className="h-full w-full object-cover object-center sm:rounded-lg"
                    />
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>

            {/* Product info */}
            <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900">
                {printData.title ? printData.title : "Your Generated Print"}
              </h1>

              

              <div className="mt-3">
                <h2 className="sr-only">Product information</h2>
              </div>

              <div className="my-6">
                <h3 className="font-bold mb-2">Description</h3>

                <div className="space-y-6 text-lg text-gray-700">
                  {printData.original_prompt}
                </div>
              </div>

              <h3 className="font-bold mb-2">View this print in 3D</h3>

              <ARImageViewer imageUrl={printData.image_paths[0]} />

              <AnimatePresence>
                {showAmendOverlay && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed inset-0 md:bg-black md:bg-opacity-50 z-50 flex justify-center items-center md:p-12"
                    onClick={handleBackdropClick}
                  >
                    <motion.div
                      initial={{ scale: 0.8 }}
                      animate={{ scale: 1 }}
                      exit={{ scale: 0.8 }}
                      className="bg-white w-full h-full overflow-auto md:w-2/3 lg:w-1/2 rounded-xl flex flex-col items-center pt-20 p-8 md:pt-8 md:shadow-xl"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <button
                        onClick={toggleAmendOverlay}
                        className="absolute top-4 right-4 z-50 text-lg p-2"
                      >
                        <XMarkIcon className="h-8 w-8" aria-hidden="true" />
                      </button>

                      {/* Image container using flex-grow and flex-shrink for responsiveness */}
                      <div className="flex flex-grow w-full max-h-[calc(100%-3rem)] md:max-h-[calc(100%-10rem)] overflow-hidden">
                        {printData && printData.image_paths.length > 0 && (
                          <img
                            src={printData.image_paths[1]}
                            alt="Product Image"
                            className="w-auto h-auto max-w-full max-h-full mx-auto"
                            style={{ objectFit: "contain" }}
                          />
                        )}
                      </div>

                      {/* Form container ensuring it's always fully visible */}
                      <div className="w-full flex-shrink-0 mt-4">
                        <AmendGeneratedImage
                          onNewImage={handleNavigateToNewImage}
                          originalId={slug}
                        />
                      </div>
                    </motion.div>
                  </motion.div>
                )}
              </AnimatePresence>

              <div>
                <Cards
                  data={printFormats}
                  label="Format"
                  onChange={handleFormatChange}
                  defaultSelection={userSelection.format}
                />
              </div>
              {userSelection.format !== 1 && (
                <div className="mt-6">
                  <Cards
                    data={printSizes}
                    label="Size"
                    onChange={handleSizeChange}
                    defaultSelection={userSelection.size}
                  />
                </div>
              )}

              {userSelection.format === 3 && (
                <div className="mt-6">
                  <Cards
                    data={printExtras}
                    label="Extras"
                    onChange={handleExtrasChange}
                    defaultSelection={userSelection.extras}
                  />
                </div>
              )}

              <div className="my-10 flex">
                <Button
                  onClick={handleAddToBasket}
                  className="text-xl font-medium pl-12 pr-12 w-full md:w-auto"
                  disabled={addingToBasket}
                  loading={addingToBasket}
                >
                  {addingToBasket ? "Adding to bag" : "Add to bag"}
                </Button>
                <div className="flex items-center">
                  <p className="ml-4 text-3xl tracking-tight text-gray-900">
                    {formatCurrency(price)}
                  </p>
                </div>
              </div>

              <div className="my-6">
                <h3 className="font-bold mb-2 text-lg">Original Description</h3>

                <div className="space-y-6 text-sm text-gray-700">
                  {printData.original_prompt}
                </div>
              </div>

              <section aria-labelledby="details-heading" className="mt-12">
                <h2 id="details-heading" className="sr-only">
                  Additional details
                </h2>

                {/* <div className="divide-y divide-gray-200 border-t">
                  {product.details.map((detail) => (
                    <Disclosure as="div" key={detail.name}>
                      {({ open }) => (
                        <>
                          <h3>
                            <Disclosure.Button className="group relative flex w-full items-center justify-between py-6 text-left">
                              <span
                                className={classNames(
                                  open ? "text-indigo-600" : "text-gray-900",
                                  "text-sm font-medium"
                                )}
                              >
                                {detail.name}
                              </span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusIcon
                                    className="block h-6 w-6 text-indigo-400 group-hover:text-indigo-500"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusIcon
                                    className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel
                            as="div"
                            className="prose prose-sm pb-6"
                          >
                            <ul role="list">
                              {detail.items.map((item) => (
                                <li key={item}>{item}</li>
                              ))}
                            </ul>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </div> */}
              </section>
            </div>
          </div>
        ) : (
          <div>Loading</div>
        )}
      </div>
    </div>
  );
};

export default GeneratedPrint;
