const Footer = () => {
  return (
    <footer className="py-4 px-6 border-t border-stone-300 border-solid text-sm">
      <div className="mx-auto flex justify-between items-center max-w-2xl md:max-w-7xl">
        <a href="/privacy-policy" title="Privacy Policy">
          Privacy Policy
        </a>
      </div>
    </footer>
  );
};

export default Footer;
