import Spinner from "./Spinner/Spinner";
import { useNavigate } from "react-router-dom";

const Button = (props) => {
  const navigate = useNavigate();
  const { className, children, disabled, loading, onClick, url, spinnerSpeed} = props;
  const classNames = `flex items-center rounded-full bg-slate-900 px-6 py-3 text-zinc-100 enabled:hover:bg-slate-950 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-25 disabled:cursor-not-allowed justify-center ${className ? className : ""}`;

  const handleClick = () => {
    if (url) {
      navigate(url);
    } else {
      onClick && onClick();
    }
  };

  return (
    <>
      <button
        onClick={handleClick}
        onTouchStart={handleClick}
        type="button"
        className={classNames}
        disabled={disabled}
      >
        {loading ? (
          <div className="mr-2">
            <Spinner size={24} color="#FFF" speed={spinnerSpeed} />
          </div>
        ) : (
          ""
        )}
        <div className="flex items-center">{children}</div>
      </button>
    </>
  );
};

export default Button;
