const PageHeader = (props) => {
    const {className, title} = props;    
    const classNames = `text-2xl md:text-4xl font-medium text-slate-950 ${className ? className : ""}`
    return (
        <div className="mb-8">
        <h1 className={classNames}>{title}</h1>
        </div>
    )
}

export default PageHeader