import React, { useEffect, useRef, useState } from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { rgbToHex } from "../../utility/rgbToHex";
import Button from "../Button";

const InputKeywords = (props) => {
  const {
    className,
    colors,
    disabled,
    onChange = () => {},
    keywords,
    placeholder,
  } = props;
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [input, setInput] = useState("");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [currentColor, setCurrentColor] = useState("");
  const [allowClose, setAllowClose] = useState(false);

  const pickerRef = useRef(null);
  const classNames = `flex flex-wrap items-center gap-2 p-2 border border-gray-300 rounded-md bg-white ${
    disabled ? "opacity-50" : ""
  } ${className ? className : ""}`;

  useEffect(() => {
    setSelectedKeywords(Array.isArray(keywords) ? keywords : []);
  }, [keywords]);

  useEffect(() => {
    const handleMouseUpOutside = (event) => {
      if (
        allowClose &&
        pickerRef.current &&
        !pickerRef.current.contains(event.target)
      ) {
        setShowColorPicker(false);
      }
    };

    document.addEventListener("mouseup", handleMouseUpOutside);
    return () => document.removeEventListener("mouseup", handleMouseUpOutside);
  }, [allowClose]);

  const handleFocus = () => {
    setShowColorPicker(true);
    setAllowClose(false);
    setTimeout(() => setAllowClose(true), 200);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && input && !colors) {
      const newSelection = [...selectedKeywords, input];
      setSelectedKeywords(newSelection);
      onChange(newSelection);
      setInput("");
    }
  };

  const addColorToKeywords = () => {
    if (currentColor) {
      const newKeywords = [...selectedKeywords, currentColor];
      setSelectedKeywords(newKeywords);
      onChange(newKeywords);
      setCurrentColor("");
      setShowColorPicker(false);
    }
  };

  const handleColorChange = (color) => {
    setCurrentColor(color);
  };

  const removeKeyword = (keywordToRemove) => {
    const newKeywords = selectedKeywords.filter(
      (keyword) => keyword !== keywordToRemove
    );
    setSelectedKeywords(newKeywords);
    onChange(newKeywords);
  };

  return (
    <div className={classNames}>
      {selectedKeywords &&
        selectedKeywords.length > 0 &&
        selectedKeywords.map((keyword, index) => (
          <div
            key={index}
            className="flex items-center bg-slate-950 text-white text-sm px-2 py-1 rounded-full"
          >
            {colors ? (
              <span
                className="w-4 h-4 mr-2 rounded-full border-solid border-white border-2"
                style={{ backgroundColor: keyword }}
              ></span>
            ) : (
              ""
            )}
            {colors ? rgbToHex(keyword) : keyword}
            <button
              onClick={() => removeKeyword(keyword)}
              className="ml-1 text-xs"
            >
              <XMarkIcon className="h-4 w-4 cursor-pointer" />
            </button>
          </div>
        ))}
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={handleKeyDown}
        onFocus={() => colors && handleFocus()}
        className="flex-1 p-1 border-none"
        placeholder={
          placeholder
            ? placeholder
            : !colors
            ? "Type keywords and hit enter..."
            : "Click to add colours"
        }
        aria-label="Enter keywords"
        disabled={disabled}
      />
      {showColorPicker && (
        <div
          ref={pickerRef}
          className="absolute z-50 p-4 bg-white sm:drop-shadow-xl sm:border-slate-200/50 sm:border sm:rounded-xl bottom-0 left-0 top-0 right-0 sm:bottom-20 sm:left-auto sm:top-auto sm:right-auto flex flex-col justify-end items-center"
          onClick={(e) => e.stopPropagation()}
        >
          <HexColorPicker
            color={currentColor}
            onChange={handleColorChange}
            className="!w-full"
          />
          <HexColorInput
            color={currentColor}
            onChange={handleColorChange}
            className="p-2 rounded-md w-full mt-2 border-slate-300"
          />
          <Button
            className="p-2 text-sm mt-2 w-full"
            onClick={addColorToKeywords}
          >
            Add Color
          </Button>
        </div>
      )}
    </div>
  );
};

export default InputKeywords;
