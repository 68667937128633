export const rgbToHex = (rgb) => {
    if (/^#([0-9A-F]{3}){1,2}$/i.test(rgb)) {
      return rgb;
    }
    const components = rgb.match(/\d+/g);    
    if (components) {      
      const hex = components.map(component => {
        const hexComponent = parseInt(component, 10).toString(16).toUpperCase();
        return hexComponent.length === 1 ? `0${hexComponent}` : hexComponent;
      }).join('');
  
      return `#${hex}`;
    } else {      
      return '#000000';
    }
};