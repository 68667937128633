import React, { useContext, useEffect, useState } from "react";
import { Auth } from "@supabase/auth-ui-react";
import { AuthContext } from "./AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

const Login = () => {
  const { supabase, session } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState(null);

  // Redirects after successful login
  useEffect(() => {
    if (session) {
      const from = location.state?.from?.pathname || "/";
      navigate(from);
    } else {
      setLoading(false);
    }
  }, [session, navigate, location.state]);

  useEffect(() => {
    if (location.state?.message) {
      setMessage(location.state.message);
    }
  }, [location]);

  return (
    <div className="container mx-auto p-4 h-screen md:h-auto md:p-6 md:w-2/4 bg-white md:rounded-xl md:mt-20">
      {loading ? (
        <div>Loading</div>
      ) : (
        <>
          {message && (
            <div className="rounded-md bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon
                    className="h-5 w-5 text-blue-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-blue-700">{message}</p>
                </div>
              </div>
            </div>
          )}
          <Auth
            supabaseClient={supabase}
            providers={["google"]}
            appearance={{
              extend: true,
              className: {
                button:
                  "border-none flex rounded-md bg-slate-900 px-6 py-3 text-zinc-100 shadow-sm enabled:hover:bg-slate-950 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",

                container: "mb-2",
                input: "rounded-md",
                label: "mb-2",
                divider: "bg-slate-300",
              },
            }}
          />
        </>
      )}
    </div>
  );
};

export default Login;
