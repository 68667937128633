const ProgressBar = (props) => {
    const {className, current, label, steps = false, total} = props;
    const progressPercentage = steps ? ((current +  1) / total.length) * 100 : (current / total * 100);    

    const classNames = `w-full bg-white rounded-full h-2.5 ${className}`
    return (
        <>
        {label && (<h5 className="text-sm text-slate-700">{current} {label}</h5>)}
      <div className={classNames}>
        <div
          className="bg-slate-950 h-2.5 rounded-full"
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
      </>
    );
  };

  export default ProgressBar;