import ArtGallery from "../../components/ArtGallery/ArtGallery";
import PageHeader from "../../components/PageHeader/PageHeader";

const Prints = () => {
  return (
    <div className="container mx-auto p-8 max-w-2xl md:max-w-7xl ">
      <PageHeader title="Shop Prints" />
      <ArtGallery />
    </div>
  );
};

export default Prints;
