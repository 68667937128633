import { useContext, useEffect, useState } from "react";
import TextArea from "./TextArea";
import InputKeywords from "./InputKeywords/InputKeywords";
import Button from "./Button";
import { postData, postToApi } from "../utility/postData";
import { fetchData } from "../utility/fetchData";
import { AuthContext } from "../AuthContext";
import { InputColors } from "./InputColors/InputColors";

export const AmendGeneratedImage = (props) => {
  const { onNewImage, originalId } = props;
  const { session } = useContext(AuthContext);
  const [prompt, setPrompt] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [colors, setColors] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState({});
  const [primaryImage, setPrimaryImage] = useState(null)

  useEffect(() => {
    const getGenerationJob = async () => {
      if (originalId && originalId !== undefined) {
        try {
          const data = await fetchData(
            "generation_jobs",
            "*, prompt_sizes(value), art_styles(value)",
            {
              id: originalId,
            }
          );
          setData(data[0]);
        } catch (error) {
          console.error("There was an error obtaining the original job", error);
        }
      }
    };
    getGenerationJob();
  }, [originalId]);

  useEffect(() => {        
    setKeywords(data.keywords || []);
    setPrimaryImage((data?.image_paths?.length > 0 && data.image_paths[0]) || "")
  }, [data]);

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleKeywordsChange = (keywords) => {
    setKeywords(keywords);
  };

  const handleColorsChange = (colors) => {
    console.log("handle colors change")
    console.log(colors)
    setColors(colors)
  }

  const isUpdateDisabled = (prompt.trim().length === 0 && keywords.length === 0) || submitting;

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      const { art_styles, prompt_size, prompt_sizes, style, revised_prompt } =
        data;
      const revisedPrompt = `Use the prompt below to generate an image but this time make sure to follow these instructions: ${prompt} ${colors.length > 0 ? (colors.length > 1 ? `using these colors ${colors.join(", ")}` : `using this color ${colors[0]}`) : ""} Original prompt: ${revised_prompt}`;
      const response = await postData("generation_jobs", {
        prompt_size,
        original_prompt: prompt,
        user_id: session.user.id,
        style,
        keywords,
        prompt_type: 1,
        seed_id: originalId,
      });
      const generateImage = await postToApi(
        `${process.env.REACT_APP_API_URL}/generate-image`,
        {
          prompt: revisedPrompt,
          size: prompt_sizes.value,
          style: art_styles?.value ? art_styles.value : null,
          user: session.user.id,
          job_id: response.data[0].id,
          keywords,
          quality: "hd",
          forceOriginalPrompt: false,
          revision: true,
        }
      );

      if (generateImage.error) {
        console.error("Failed to post data:", generateImage.error);
      } else {
        const slug = response.data[0].id;
        onNewImage(slug);        
      }
    } catch (error) {
      console.error("Error in postGenerationJob: ", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <h3 className="font-bold mb-4 text-2xl">Amend this print</h3>
      <div className="mb-2">What do you want to change?</div>
      <div className="mb-4">
        <TextArea
          onChange={handlePromptChange}
          disabled={submitting}
          placeholder="Describe what you want to change in your print."
        />
      </div>
      <div className="mb-6">
        <div className="mb-2">Add to this print</div>
        <InputKeywords onChange={handleKeywordsChange} disabled={submitting} keywords={keywords} />
      </div>
      <div className="mb-6">
        <div className="mb-2">Colour Scheme</div>
        <InputColors imageUrl={primaryImage} paletteAmount={5} onChange={handleColorsChange} />
      </div>
      <Button
        className="w-full md:w-auto"
        loading={submitting}
        onClick={handleSubmit}
        disabled={isUpdateDisabled}
        spinnerSpeed=".5s"
      >
        {submitting ? "Updating Print..." : "Update Print"}
      </Button>
    </div>
  );
};
