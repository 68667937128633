import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../AuthContext";
import { fetchData } from "../../utility/fetchData";
import Tabs from "../../components/Tabs/Tabs";
import ArtGallery from "../../components/ArtGallery/ArtGallery";
import PageHeader from "../../components/PageHeader/PageHeader";
import Orders from "../../components/Orders/Orders"

const Account = () => {
  const { session } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState("Generated Prints")

  const handleTabChange = (tabName) => {
    setActiveTab(tabName)
  }

  const TabPaneOne = () => {
    return <ArtGallery userId={session.user.id} />;
  };

  const TabPaneTwo = () => {
    return (
      <Orders />
    );
  };

  const TabPaneThree = () => {
    return (
      <div>
        <h2>Content for Tab Three</h2>
        <p>This is the content for the third tab.</p>
      </div>
    );
  };

  return (
    <div className="container mx-auto p-8 max-w-2xl md:max-w-7xl">
      <PageHeader title="My Account" />
      <div className="mt-6 mb-12">
        <Tabs onChange={handleTabChange }>
          <TabPaneOne name="Generated Prints" />
          <TabPaneTwo name="Orders" />
          {/* <TabPaneThree name="Settings" /> */}
        </Tabs>
      </div>
    </div>
  );
};

export default Account;
