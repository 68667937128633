import React, { createContext, useContext, useEffect, useState } from "react";

// Create a context
const BasketContext = createContext();

// Provider component
export const BasketProvider = ({ children }) => {
  const [basketQuantity, setBasketQuantity] = useState(0);
  const [basketItems, setBasketItems] = useState(() => {
    try {
      const localData = localStorage.getItem("basket");
      return localData ? JSON.parse(localData) : [];
    } catch (error) {
      console.error("Error reading basket from localStorage", error);
      return [];
    }
  });

  useEffect(() => {
    // Save basket items to localStorage whenever they change
    localStorage.setItem("basket", JSON.stringify(basketItems));
    countBasket();
  }, [basketItems]);

  const countBasket = () => {
    const count = basketItems.reduce((total, item) => total + item.quantity, 0);
    setBasketQuantity(count);
  };

  const addToBasket = async (newItem) => {
    setBasketItems((currentItems) => {
      const existingItemIndex = currentItems.findIndex(
        (item) => item.id === newItem.id
      );
      if (existingItemIndex !== -1) {
        // Update quantity of the existing item
        return currentItems.map((item, index) =>
          index === existingItemIndex
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        // Add new item
        return [...currentItems, newItem];
      }
    });
  };

  const removeItem = (itemId) => {
    setBasketItems((currentItems) =>
      currentItems.filter((item) => item.id !== itemId)
    );
  };

  const emptyBasket = () => {
    setBasketItems([]);
  };

  return (
    <BasketContext.Provider
      value={{
        basketItems,
        addToBasket,
        removeItem,
        basketQuantity,
        emptyBasket,
      }}
    >
      {children}
    </BasketContext.Provider>
  );
};

export const useBasket = () => useContext(BasketContext);
