const PrivacyPolicy = () => {
  return (
    <div className="max-w-3xl mx-auto p-6 mt-10">
      <h1 className="text-3xl font-bold mb-4">
        Privacy Policy for Createprints.ai
      </h1>
      <p className="mb-4">
        <strong>Effective Date:</strong> 21/06/24
      </p>
      <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
      <p className="mb-4">
        Welcome to Createprints.ai. We are committed to protecting your privacy
        and ensuring that your personal information is handled in a safe and
        responsible manner. This Privacy Policy outlines how we collect, use,
        and protect your information when you visit our website.
      </p>
      <h2 className="text-2xl font-semibold mb-2">2. Contact Information</h2>
      <p className="mb-4">
        If you have any questions or concerns about this Privacy Policy or our
        data practices, please contact us at:
      </p>
      <p className="mb-4">
        <strong>Email:</strong> support@createprints.ai
      </p>
      <h2 className="text-2xl font-semibold mb-2">3. Information We Collect</h2>
      <p className="mb-4">
        We collect the following personal information from you when you create
        an account or place an order:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Account Creation:</strong> First name, last name, and email
          address.
        </li>
        <li>
          <strong>Order Fulfillment:</strong> Full address, including recipient
          names.
        </li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">
        4. How We Use Your Information
      </h2>
      <p className="mb-4">
        We use the information we collect from you for the following purposes:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Account Management:</strong> To create and manage your user
          account.
        </li>
        <li>
          <strong>Order Fulfillment:</strong> To process and fulfill your
          orders.
        </li>
        <li>
          <strong>Website Functionality:</strong> To improve website
          functionality, such as basket management.
        </li>
        <li>
          <strong>Analytics:</strong> To analyze website usage and improve our
          services.
        </li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">
        5. Cookies and Tracking Technologies
      </h2>
      <p className="mb-4">
        We use cookies and similar tracking technologies for analytics and
        website functionality. These technologies help us understand how you
        interact with our site and enhance your user experience.
      </p>
      <h2 className="text-2xl font-semibold mb-2">
        6. User Rights and Data Deletion
      </h2>
      <p className="mb-4">
        If you wish to delete your account, please contact us at
        support@createprints.ai with the email subject "account deletion
        request." We will process your request and delete your data accordingly.
      </p>
      <h2 className="text-2xl font-semibold mb-2">7. Data</h2>
      <p className="mb-4">
      We store your personal data on secure servers provided by trusted third-party hosting providers. These providers are contractually obligated to implement strict security measures to protect your data. Your data may be transferred to and stored in countries outside of your country of residence, which may have different data protection laws. Regardless of the location, we ensure that appropriate safeguards are in place to protect your data in accordance with applicable data protection regulations. By using our services, you consent to the transfer, processing, and storage of your data as described in this Privacy Policy.
      </p>            
      <h2 className="text-2xl font-semibold mb-2">8. Policy Updates</h2>
      <p className="mb-4">
        We may update this Privacy Policy from time to time. Any changes will be
        posted on our Privacy Policy webpage at createprints.ai/privacy. We
        encourage you to review this page periodically for the latest
        information on our privacy practices.
      </p>
      <h2 className="text-2xl font-semibold mb-2">9. International Users</h2>
      <p className="mb-4">
        Users from outside the UK can sign up for our services. By using our
        site, you consent to the transfer of your personal information to the UK
        and the application of UK privacy laws.
      </p>
      <h2 className="text-2xl font-semibold mb-2">
        10. Additional Information
      </h2>
      <p className="mb-4">
        We do not share your data with third parties or use it for marketing
        purposes.
      </p>
      <p className="mt-6">
        By using our website, you consent to the collection and use of your
        personal information as described in this Privacy Policy. Thank you for
        trusting Createprints.ai with your personal data.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
