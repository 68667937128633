import { useCallback, useEffect, useState } from "react";
import { fetchData } from "../../utility/fetchData";
import { useSearchParams } from "react-router-dom";
import { useBasket } from "../../basketContext";
import Button from "../../components/Button";

const OrderConfirmation = () => {
  const [orderData, setOrderData] = useState(null);
  const [error, setError] = useState(false);
  const [searchParams] = useSearchParams();
  const [showDeliveryAddress, setShowDeliveryAddress] = useState(false);
  const sessionId = searchParams.get("sessionId");
  const { emptyBasket } = useBasket();

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP", // You can change 'USD' to your desired currency code
      // Other options like minimumFractionDigits can be added for more control
    }).format(amount);
  };

  const orderItems =
    orderData && orderData.order_items
      ? orderData.order_items.map((item) => (
          <div key={item.id}>
            <strong>{item.description}</strong>
            <p>{formatCurrency(item.price / 100)}</p>
            {item.digital_download && (
              <p>Your purchase will shortly be delivered by email.</p>
            )}
          </div>
        ))
      : null;

  const fetchOrderData = useCallback(async () => {
    try {
      const data = await fetchData("orders", "*, order_items (*)", {
        stripe_session_id: sessionId,
      });
      if (data.length > 0) {
        setOrderData(data[0]);
      }
    } catch (error) {
      console.error("Error fetching order: ", error);
      setError(true);
    }
  }, [sessionId]);

  useEffect(() => {
    emptyBasket();
      fetchOrderData();        
  }, []);

  useEffect(() => {    
    if (orderData && orderData.order_items && !error) {
      const allDigital = orderData.order_items.every(
        (item) => item.digital_download
      );
      const someDigital = orderData.order_items.some(
        (item) => item.digital_download
      );
      setShowDeliveryAddress(!allDigital && someDigital);
    }
  }, [orderData]);

  return (
    <div className="container mx-auto p-6 flex flex-col justify-center text-center">
      {error && (
        <>
          <h1 className="text-4xl mb-4">
            Order failed
          </h1>
          <p>You have not been charged for this transaction. Please <a href="mailto:support@createprints.ai" className="underline decoration-solid">contact support</a>.</p>
        </>
      )}
      {orderData && (
        <>
          <h1 className="text-4xl mb-4">Thanks for your order</h1>
          <p className="mb-6">
            A confirmation has been sent to {orderData.email}.{" "}
            {showDeliveryAddress &&
              `We will let you
            know as soon as it has been dispatched.`}
          </p>
          {showDeliveryAddress && (
            <div className="my-8">
              <h2 className="text-lg font-semibold mb-2">Deliver to</h2>
              <div>
                {orderData.address_name ? (
                  <div>{orderData.address_name}</div>
                ) : null}
                <div>{orderData.address_line_1}</div>
                {orderData.address_line_2 ? (
                  <div>{orderData.address_line_2}</div>
                ) : null}
                {orderData.city ? <div>{orderData.city}</div> : null}
                {orderData.address_line_2 ? (
                  <div>{orderData.address_line_2}</div>
                ) : null}
                {orderData.state ? <div>{orderData.state}</div> : null}
                {orderData.postal_code ? (
                  <div>{orderData.postal_code}</div>
                ) : null}
                {orderData.country ? <div>{orderData.country}</div> : null}
              </div>
            </div>
          )}
          <h2 className="text-lg font-semibold mb-2">Order Items</h2>
          {orderItems}
        </>
      )}
      <Button url="/account" className="mt-8">
        My Account
      </Button>
    </div>
  );
};

export default OrderConfirmation;
