import { supabase } from "../supabase";

export const postData = async (tableName, data) => {
  let responseStructure = {
    data: null,
    error: null
  };

  try {
    const { data: insertedData, error } = await supabase
      .from(tableName)
      .insert([data])
      .select();

    if (error) {
      console.error("Error inserting data:", error);
      responseStructure.error = error;
    } else {
      responseStructure.data = insertedData;
    }
  } catch (error) {
    console.error("Error in postData:", error);
    responseStructure.error = error;
  }

  return responseStructure;
};

export const postToApi = async (url, data) => {
  let responseStructure = {
    data: null,
    error: null
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      // Handle non-2xx HTTP responses
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    responseStructure.data = result;    
  } catch (error) {
    console.error("Error posting data to API:", error);
    responseStructure.error = error;
  }

  return responseStructure;
};
