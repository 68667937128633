import { supabase } from "../supabase"; // Import your Supabase client

// Function to fetch items from a specific table and column with an optional where clause
export const fetchData = async (
  tableName,
  columnName,
  whereClause = {},
  range,
  single,
  filters = {}
) => {
  let query = supabase.from(tableName).select(columnName);

  // Apply where clause if provided
  if (Object.keys(whereClause).length !== 0) {
    Object.entries(whereClause).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        query = query.in(key, value);
      } else {
        query = query.eq(key, value);
      }
    });
  }

   // Apply additional filters
  Object.entries(filters).forEach(([key, value]) => {
    if (key === "recommended_room" || key === "keywords") {
      if (!Array.isArray(value)) {
        value = [value]; // Ensure value is an array
      }
      query = query.contains(key, value); // Use contains for array fields
    } else if (Array.isArray(value)) {
      query = query.in(key, value);
    } else {
      query = query.eq(key, value);
    }
  });

  if (range !== undefined && Object.keys(range).length !== 0) {
    query = query
      .order("created_at", { ascending: false })
      .range(range.start, range.end);
  }

  if (single) {
    query = query.limit(1).single();
  }

  // Log the query for debugging
  console.log("Supabase Query:", query);

  let { data, error } = await query;

  // Log the response or error for debugging
  if (error) {
    console.error("Error fetching data:", error);
    return error;
  } else {
    console.log("Fetched Data:", data);
    return data;
  }
};
