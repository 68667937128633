import { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Cards = (props) => {
  const { data, disabled, label, onChange, defaultSelection, showIcon = true } = props;
  const [selected, setSelected] = useState('');

  useEffect(() => {      
    if (defaultSelection !== undefined) {
      const selectedItem = data.find(item => item.id === defaultSelection);
      setSelected(selectedItem ? selectedItem.value : '');
    }
  }, [defaultSelection, data]);

  const handleChange = (value) => {
    let item = data.find(item => item.value === value);
    setSelected(value);
    onChange(item);
  };

  return (
    <RadioGroup value={selected} onChange={handleChange} disabled={disabled}>
      <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">
        {label}
      </RadioGroup.Label>

      <div className={`mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-${data.length < 3 ? data.length : "3"} sm:gap-x-4`}>
        {data.map((item, index) => (
          <RadioGroup.Option
            key={index}
            value={item.value}
            className={({ active }) =>
              classNames(
                active
                  ? "border-indigo-600 ring-2 ring-indigo-600"
                  : "border-gray-300",
                "relative flex items-center justify-center cursor-pointer rounded-lg border bg-white px-4 py-8 shadow-sm focus:outline-none"
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1 justify-center">
                  <span className="flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className="block text-sm font-medium text-gray-900"
                    >
                      {item.image_path !== undefined ? (<img src={`${item.image_path.toLowerCase()}`} className="mb-4"/>) : null}
                      <div className="text-lg text-center">{item.friendly_name}</div>
                      {item.description && (<div className="text-sm text-slate-600 font-light text-center mt-1">{item.description}</div>) }
                    </RadioGroup.Label>
                  </span>
                </span>
                {showIcon? (
                <CheckCircleIcon
                  className={classNames(
                    !checked ? "invisible" : "",
                    "h-5 w-5 text-indigo-600"
                  )}
                  aria-hidden="true"
                />
                ) : null}
                <span
                  className={classNames(
                    active ? "border" : "border-2",
                    checked ? "border-indigo-600" : "border-transparent",
                    "pointer-events-none absolute -inset-px rounded-lg"
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default Cards;
