const TextArea = (props) => {
  const { disabled, label, onChange, name, placeholder, value } = props;
  return (
    <div>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="mt-2">
        <textarea
          rows={4}
          name={name}
          id={name}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:opacity-30"
          defaultValue={value}
          onChange={(e) => onChange(e)}         
          placeholder={placeholder} 
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default TextArea;
