import React, {useEffect, useState} from "react"
import ColorThief from 'colorthief'
import InputKeywords from "../InputKeywords/InputKeywords";
import {rgbToHex} from "../../utility/rgbToHex"

export const InputColors = (props) => {
    const {imageUrl, paletteAmount, onChange} = props;
    const [colorPalette, setColorPalette] = useState([])

    const handleColorsChange = (colors) => {
        onChange(colors)        
    }

    useEffect(() => {
        const img = new Image();
        img.crossOrigin = 'Anonymous'; // Important for CORS        
        img.src = imageUrl
        img.onload = () => {
            const colorThief = new ColorThief();
            // Get the main three colors from the image
            const dominent = colorThief.getColor(img);
            const palette = colorThief.getPalette(img, paletteAmount);
            setColorPalette(palette.map(color => `rgb(${color[0]}, ${color[1]}, ${color[2]})`));            
        };
    }, [imageUrl]);

    return (<div><InputKeywords keywords={colorPalette} colors onChange={handleColorsChange}/></div>)
}