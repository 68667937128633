import { useEffect, useState } from "react";
const ImagesWithDetails = (props) => {
  const { data, defaultSelection, onChange } = props;
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState('');

  useEffect(() => {      
    if (defaultSelection !== null) {
      const selectedItem = data.find(item => item.id === defaultSelection.id);
      setSelected(selectedItem ? selectedItem.id : null);
    }    
  }, [defaultSelection, data]);

  const handleChange = (value) => {
    let item = data.find(item => item.id === value.id);
    setSelected(item.id);    
    onChange(item.id);    
  };

  useEffect(() => {
    if (data.length > 0) {
      setLoading(false);
    }
  }, [data]);

  return loading ? (
    <div>Loading</div>
  ) : (
    <>      
      <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8">
        {data.map((item, index) => (
          <li key={index} className="relative" onClick={() => handleChange(item)}>            
            <div className={`group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 ${selected === item.id ? "ring-2 ring-indigo-600" : ""} focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100`}>
              <img
                src={item.image_url}
                alt={item.friendly_name}
                className="pointer-events-none object-cover group-hover:opacity-75"
              />
              <button
                type="button"
                className="absolute inset-0 focus:outline-none"
              >
                <span className="sr-only">
                  View details for {item.friendly_name}
                </span>
              </button>
            </div>
            <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
              {item.friendly_name}              
            </p>
          </li>
        ))}
      </ul>
    </>
  );
};

export default ImagesWithDetails;
