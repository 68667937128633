import React, {useState} from "react"

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
}

const Tabs = (props) => {
  const { children, onChange } = props;
  const tabs = React.Children.map(children, (child) => child.props.name)
  const [activeTab, setActiveTab] = useState(tabs[0])

  const handleTabClick = (tab) => {
    setActiveTab(tab)
    onChange();
  }

  return (
    <div>
      <div className="border-b border-gray-200 mb-6">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab}
              onClick={() => handleTabClick(tab)}
              className={classNames(
                tab === activeTab
                  ? "border-indigo-500 text-indigo-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
              )}
              aria-current={tab === activeTab ? "page" : undefined}
            >
              {tab}
            </button>
          ))}
        </nav>
      </div>
      <div>
        {React.Children.map(children, (child) =>
          child.props.name === activeTab ? child : null
        )}
      </div>
    </div>
  );
};

export default Tabs;
